import React from 'react';
import { FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-gray-900 to-gray-950 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              NextBiti
            </h3>
            <p className="text-sm text-gray-400 leading-relaxed">
              Transforming sales strategies with cutting-edge AI solutions for modern businesses.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {['Features', 'Pricing', 'About', 'Contact'].map((item) => (
                <li key={item}>
                  <Link 
                    to={`/${item.toLowerCase()}`}
                    className="text-gray-400 hover:text-white transition-colors duration-300"
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Stay Updated</h4>
            <div className="mt-4">
              <div className="flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="bg-gray-800 text-gray-300 px-4 py-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                />
                <button className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-r-lg hover:opacity-90 transition-opacity">
                  <FaEnvelope />
                </button>
              </div>
            </div>
          </div>

          {/* Social Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Connect With Us</h4>
            <div className="flex space-x-4">
              {[
                { Icon: FaTwitter, href: '#' },
                { Icon: FaLinkedin, href: '#' },
                { Icon: FaGithub, href: '#' }
              ].map(({ Icon, href }, index) => (
                <a
                  key={index}
                  href={href}
                  className="text-gray-400 hover:text-white transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-12 pt-8">
        <div className="flex space-x-6 mt-4 md:mt-0">
  <Link to="/privacy" className="text-sm text-gray-400 hover:text-white transition-colors duration-300">
    Privacy Policy
  </Link>
  <Link to="/terms" className="text-sm text-gray-400 hover:text-white transition-colors duration-300">
    Terms of Service
  </Link>
  <a 
    href="https://nextbiti.com" 
    className="text-sm text-gray-400 hover:text-white transition-colors duration-300"
    target="_blank"
    rel="noopener noreferrer"
  >
    Visit NextBiti.com
  </a>
  <a 
    href="mailto:info@nextbiti.com"
    className="text-sm text-gray-400 hover:text-white transition-colors duration-300 flex items-center"
  >
    <FaEnvelope className="mr-2 h-4 w-4" />
    info@nextbiti.com
  </a>
</div>
</div>
      </div>
    </footer>
  );
};

export default Footer;