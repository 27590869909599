import React from 'react';
import { FaCheck } from 'react-icons/fa';

const PricingCard = ({ plan, price, features, isPopular }) => (
  <div className={`relative group bg-white/80 backdrop-blur-md rounded-2xl p-8 border border-gray-200/50 transition-all duration-300 hover:shadow-2xl ${isPopular ? 'scale-105' : ''}`}>
    {isPopular && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <span className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-4 py-1 rounded-full text-sm font-medium">
          Most Popular
        </span>
      </div>
    )}
    
    <div className="space-y-4">
      <h3 className="text-2xl font-bold text-center">{plan}</h3>
      <div className="text-center">
        <div className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          {price}
        </div>
        <div className="text-gray-500 mt-2">{price === 'Contact Us' ? 'Custom pricing' : '/month'}</div>
      </div>
      
      <div className="pt-8 space-y-4">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              <FaCheck className="h-5 w-5 text-blue-600" />
            </div>
            <span className="text-gray-600">{feature}</span>
          </div>
        ))}
      </div>
      
      <button className={`w-full py-3 px-6 rounded-xl font-semibold transition-all duration-300 ${
        isPopular
          ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:shadow-lg hover:shadow-blue-500/25'
          : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
      }`}>
        {price === 'Contact Us' ? 'Contact Sales' : 'Get Started'}
      </button>
    </div>
  </div>
);

const Pricing = () => {
  const plans = [
    {
      plan: 'Starter',
      price: '$29',
      features: [
        'Up to 5 team members',
        'Basic analytics',
        'Basic AI predictions',
        '24/7 support'
      ]
    },
    {
      plan: 'Pro',
      price: '$99',
      features: [
        'Up to 20 team members',
        'Advanced analytics',
        'Custom AI models',
        'Priority support',
        'API access'
      ],
      isPopular: true
    },
    {
      plan: 'Enterprise',
      price: 'Contact Us',
      features: [
        'Unlimited team members',
        'Custom solutions',
        'Dedicated manager',
        'SLA guarantee',
        'Custom integrations'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section */}
      <section className="relative py-20 bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-bold text-white mb-6">Simple, Transparent Pricing</h1>
          <p className="text-xl text-white/90 max-w-3xl mx-auto">
            Choose the perfect plan for your team's needs
          </p>
        </div>
      </section>

      {/* Pricing Cards */}
      <section className="py-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          {/* Add FAQ items here */}
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="max-w-4xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-white mb-6">Ready to get started?</h2>
          <button className="bg-white text-blue-600 py-3 px-8 rounded-xl font-semibold hover:shadow-lg transition-all duration-300">
            Start Free Trial
          </button>
        </div>
      </section>
    </div>
  );
};

export default Pricing;