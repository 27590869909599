import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

const Home = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative overflow-hidden py-32">
        {/* Animated gradient background */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 animate-gradient-x"></div>
        
        {/* Floating shapes */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute h-40 w-40 rounded-full bg-white/10 -top-20 -left-20 blur-2xl"></div>
          <div className="absolute h-56 w-56 rounded-full bg-purple-500/10 bottom-20 right-20 blur-3xl"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-6xl font-bold mb-6 text-white drop-shadow-lg bg-clip-text">
              AI-Powered Solutions for
              <span className="block mt-2 bg-gradient-to-r from-blue-200 to-purple-200 bg-clip-text text-transparent">
                Smarter Sales Strategies
              </span>
            </h1>
            <p className="text-xl mb-12 text-white/90 drop-shadow max-w-2xl mx-auto leading-relaxed">
              Transform the way you sell with our cutting-edge AI solutions, designed to help you close more deals and drive revenue growth.
            </p>
            <div className="space-x-4">
              <Link
                to="/get-started"
                className="bg-white/95 text-blue-600 font-bold py-4 px-8 rounded-xl hover:bg-white transition-all duration-300 shadow-lg hover:shadow-xl hover:-translate-y-1"
              >
                Get Started
              </Link>
              <Link
                to="/demo"
                className="bg-black/20 backdrop-blur-md text-white font-bold py-4 px-8 rounded-xl hover:bg-black/30 transition-all duration-300 border border-white/20"
              >
                Watch Demo
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Features Highlights */}
      {/* Why Choose Us Section */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Why Choose Us?
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Transform your business with AI-powered solutions
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
            {/* Predictive Analytics */}
            <div className="group bg-white/80 backdrop-blur-md p-8 rounded-2xl border border-gray-200/50 shadow-xl hover:shadow-2xl transition-all duration-300">
              <div className="relative mb-6 flex flex-col items-center">
                <div className="absolute inset-0 bg-blue-100 rounded-full w-16 h-16 blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
                <div className="relative bg-gradient-to-r from-blue-500 to-purple-500 w-16 h-16 rounded-full flex items-center justify-center">
                  <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">Predictive Analytics</h3>
              <p className="text-gray-600 leading-relaxed text-center">
                Harness the power of AI to predict future sales trends and optimize your strategy with actionable insights.
              </p>
            </div>

            {/* Lead Scoring */}
            <div className="group bg-white/80 backdrop-blur-md p-8 rounded-2xl border border-gray-200/50 shadow-xl hover:shadow-2xl transition-all duration-300">
              <div className="relative mb-6 flex flex-col items-center">
                <div className="absolute inset-0 bg-purple-100 rounded-full w-16 h-16 blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
                <div className="relative bg-gradient-to-r from-purple-500 to-pink-500 w-16 h-16 rounded-full flex items-center justify-center">
                  <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">Lead Scoring Automation</h3>
              <p className="text-gray-600 leading-relaxed text-center">
                Automatically prioritize leads based on AI-generated scoring, helping your team focus on the highest-value prospects.
              </p>
            </div>

            {/* AI Recommendations */}
            <div className="group bg-white/80 backdrop-blur-md p-8 rounded-2xl border border-gray-200/50 shadow-xl hover:shadow-2xl transition-all duration-300">
              <div className="relative mb-6 flex flex-col items-center">
                <div className="absolute inset-0 bg-pink-100 rounded-full w-16 h-16 blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
                <div className="relative bg-gradient-to-r from-pink-500 to-red-500 w-16 h-16 rounded-full flex items-center justify-center">
                  <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">AI-Powered Recommendations</h3>
              <p className="text-gray-600 leading-relaxed text-center">
                Deliver personalized product recommendations to customers with our AI-driven recommendation engine.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// Add to your CSS or tailwind.config.js
{/* 
@keyframes gradient-x {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.animate-gradient-x {
  background-size: 200% 200%;
  animation: gradient-x 15s ease infinite;
}
*/}

export default Home;