import React from 'react';
import { BriefcaseIcon, LightBulbIcon, UsersIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import Serena from '../assets/imgs/serena.jpg';
import John from '../assets/imgs/john.png';
import David from '../assets/imgs/david.png';

const About = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section */}
      <section className="relative overflow-hidden py-20 bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-bold text-white mb-6">About Us</h1>
          <p className="text-xl text-white/90 max-w-3xl mx-auto">
            Discover who we are and what drives us to create cutting-edge AI solutions for sales strategy.
          </p>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Mission Card */}
          <div className="bg-white/80 backdrop-blur-md p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-200/50">
            <div className="bg-blue-100 p-3 rounded-xl w-fit mx-auto">
              <BriefcaseIcon className="h-8 w-8 text-blue-600" />
            </div>
            <h3 className="text-2xl font-bold text-gray-800 mt-6 text-center">Our Mission</h3>
            <p className="mt-4 text-gray-600 text-center leading-relaxed">
              Empowering businesses with AI-driven solutions that optimize sales strategies and lead to sustainable growth.
            </p>
          </div>

          {/* Vision Card */}
          <div className="bg-white/80 backdrop-blur-md p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-200/50">
            <div className="bg-purple-100 p-3 rounded-xl w-fit mx-auto">
              <LightBulbIcon className="h-8 w-8 text-purple-600" />
            </div>
            <h3 className="text-2xl font-bold text-gray-800 mt-6 text-center">Our Vision</h3>
            <p className="mt-4 text-gray-600 text-center leading-relaxed">
              To revolutionize the future of sales through innovative AI technology and data-driven insights.
            </p>
          </div>

          {/* Values Card */}
          <div className="bg-white/80 backdrop-blur-md p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-200/50">
            <div className="bg-pink-100 p-3 rounded-xl w-fit mx-auto">
              <UsersIcon className="h-8 w-8 text-pink-600" />
            </div>
            <h3 className="text-2xl font-bold text-gray-800 mt-6 text-center">Our Values</h3>
            <p className="mt-4 text-gray-600 text-center leading-relaxed">
              Innovation, integrity, and customer success drive everything we do.
            </p>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 bg-gradient-to-r from-blue-600 to-purple-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
            <StatsCard number="500+" text="Clients Worldwide" />
            <StatsCard number="95%" text="Client Satisfaction" />
            <StatsCard number="10+" text="Years Experience" />
            <StatsCard number="24/7" text="Support Available" />
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-12">Meet Our Team</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <TeamMember
            image={Serena}
            name="Serena Rahnama"
            role="CEO & Founder"
          />
          <TeamMember
            image={John}
            name="John Smith"
            role="CTO"
          />
          <TeamMember
            image={David}
            name="David Chen"
            role="Head of AI"
          />
        </div>
      </section>
    </div>
  );
};

const StatsCard = ({ number, text }) => (
  <div className="text-center">
    <p className="text-4xl font-bold mb-2">{number}</p>
    <p className="text-sm opacity-80">{text}</p>
  </div>
);

const TeamMember = ({ image, name, role }) => (
  <div className="bg-white/80 backdrop-blur-md rounded-2xl overflow-hidden shadow-xl hover:shadow-2xl transition-all duration-300 group">
    <div className="relative overflow-hidden">
      <img src={image} alt={name} className="w-full h-64 object-cover group-hover:scale-105 transition-transform duration-300" />
    </div>
    <div className="p-6">
      <h3 className="text-xl font-bold text-gray-800">{name}</h3>
      <p className="text-gray-600">{role}</p>
    </div>
  </div>
);

export default About;