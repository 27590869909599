import React from 'react';
import { 
  BoltIcon, 
  ChartBarSquareIcon, 
  LifebuoyIcon,
  UserGroupIcon,
  CogIcon,
  ChatBubbleBottomCenterTextIcon
} from '@heroicons/react/24/outline';

const FeatureCard = ({ Icon, title, description, stats }) => (
  <div className="group bg-white/80 backdrop-blur-md p-8 rounded-2xl border border-gray-200/50 shadow-xl hover:shadow-2xl transition-all duration-300 relative overflow-hidden">
    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
    
    <div className="relative">
      <div className="absolute inset-0 bg-blue-100 rounded-full w-16 h-16 mx-auto blur-xl group-hover:blur-2xl transition-all duration-300 opacity-50"></div>
      <Icon className="h-12 w-12 text-blue-600 mx-auto relative transform group-hover:scale-110 transition-transform duration-300" />
    </div>

    <h3 className="text-2xl font-bold text-gray-800 mt-6 mb-4 text-center">{title}</h3>
    <p className="text-gray-600 text-center leading-relaxed">{description}</p>

    {stats && (
      <div className="mt-6 pt-6 border-t border-gray-100">
        <div className="flex justify-around text-sm">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="font-bold text-blue-600">{stat.value}</div>
              <div className="text-gray-500">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
);

const Features = () => {
  const features = [
    {
      Icon: BoltIcon,
      title: "AI Sales Predictions",
      description: "Leverage advanced machine learning to predict sales trends and optimize your strategy.",
      stats: [{ value: "95%", label: "Accuracy" }, { value: "3x", label: "Growth" }]
    },
    {
      Icon: ChartBarSquareIcon,
      title: "Smart Analytics Dashboard",
      description: "Real-time insights and visualizations to track performance metrics and KPIs.",
      stats: [{ value: "24/7", label: "Monitoring" }, { value: "10+", label: "Metrics" }]
    },
    {
      Icon: UserGroupIcon,
      title: "Team Collaboration",
      description: "Enhanced team coordination with AI-powered task distribution and performance tracking.",
      stats: [{ value: "50%", label: "More Efficient" }, { value: "100+", label: "Teams" }]
    },
    {
      Icon: CogIcon,
      title: "Process Automation",
      description: "Automate repetitive tasks and workflows to boost productivity and reduce errors.",
      stats: [{ value: "80%", label: "Time Saved" }, { value: "99%", label: "Accuracy" }]
    },
    {
      Icon: ChatBubbleBottomCenterTextIcon,
      title: "Smart Communication",
      description: "AI-powered chat and email suggestions to improve customer engagement.",
      stats: [{ value: "2x", label: "Response Rate" }, { value: "4.9", label: "Rating" }]
    },
    {
      Icon: LifebuoyIcon,
      title: "24/7 AI Support",
      description: "Round-the-clock automated support to handle customer queries and issues.",
      stats: [{ value: "1min", label: "Avg. Response" }, { value: "98%", label: "Satisfaction" }]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <section className="relative py-20 bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 overflow-hidden">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-bold text-white mb-6">AI-Powered Features</h1>
          <p className="text-xl text-white/90 max-w-3xl mx-auto">
            Transform your business with our suite of AI-powered tools and features
          </p>
        </div>
      </section>

      <section className="py-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Features;