import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'; // Import React Icons for email, phone, and location

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission logic here, such as sending data to an API
    console.log(formData);
    alert('Thank you! Your message has been sent.');
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 flex flex-col items-center justify-center">
      <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
        {/* Page Header */}
        <h2 className="text-4xl font-bold text-gray-800 text-center mb-8">Contact Us</h2>
        <p className="text-lg text-gray-600 text-center mb-12">
          We're here to help. Reach out to us with any questions or inquiries!
        </p>

        {/* Contact Form & Information Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-bold text-gray-800 mb-6">Send Us a Message</h3>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name Field */}
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold" htmlFor="name">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* Email Field */}
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* Message Field */}
              <div className="flex flex-col">
                <label className="text-gray-700 font-semibold" htmlFor="message">
                  Your Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your message here"
                  rows="5"
                  className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white font-bold py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>

          {/* Contact Information */}
          <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col justify-between">
            <h3 className="text-2xl font-bold text-gray-800 mb-6">Contact Information</h3>
            <div className="space-y-6">
              {/* Email Section */}
              <div className="flex items-center">
                <FaEnvelope className="h-6 w-6 text-blue-600" />
                <div className="ml-4">
                  <h4 className="text-lg font-semibold text-gray-800">Email</h4>
                  <p className="text-gray-600">info@yourcompany.com</p>
                </div>
              </div>

              {/* Phone Section */}
              <div className="flex items-center">
                <FaPhoneAlt className="h-6 w-6 text-blue-600" />
                <div className="ml-4">
                  <h4 className="text-lg font-semibold text-gray-800">Phone</h4>
                  <p className="text-gray-600">+1 (234) 567-890</p>
                </div>
              </div>

              {/* Address Section */}
              <div className="flex items-center">
                <FaMapMarkerAlt className="h-6 w-6 text-blue-600" />
                <div className="ml-4">
                  <h4 className="text-lg font-semibold text-gray-800">Address</h4>
                  <p className="text-gray-600">123 Main St, Anytown, USA</p>
                </div>
              </div>
            </div>

            {/* Working Hours */}
            <div className="mt-6">
              <h4 className="text-lg font-semibold text-gray-800">Working Hours</h4>
              <p className="text-gray-600">Mon - Fri: 9 AM - 6 PM</p>
              <p className="text-gray-600">Sat - Sun: Closed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;